import type { User } from "~/models/User"

export function useCan() {
    const { user } = useSanctumAuth<User>()
    function can(permission: string[] | string): boolean {
        if (permission && user.value) {
            if (typeof permission == "string") {
                return (
                    ((user.value?.permissions ?? []).findIndex((p) => p == permission) !=
                    -1) || ((user.value?.all_permissions ?? []).findIndex((p) => p == permission) !=
                    -1) 
                )
            }

            for (const permissionElement of permission) {
                if (
                    (user.value?.permissions ?? []).findIndex(
                        (p) => p == permissionElement
                    ) != -1

                    ||
                    
                    (user.value?.all_permissions ?? []).find(
                        (p) => p.includes(permissionElement)
                    )

                ) {
                    return true
                }
            }

            return false
        }

        return false
    }

    return can
}
